:root {
  --get-site-color-theme: white;
}

.ck-rounded-corners .ck.ck-balloon-panel,
.ck.ck-balloon-panel.ck-rounded-corners {
  z-index: 9999 !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background: var(--get-site-color-theme);
  border: 1px solid grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: grey;
  box-shadow: inset 0 0 5px grey;
}

/*# sourceMappingURL=index.css.map */


/* Fixing Single Medicine Additional Info Showing underline & blue text for anchor tags */
p.MuiTypography-root.MuiTypography-body2 * {
  text-decoration: none !important;
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
}