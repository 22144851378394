:root
    --get-site-color-theme: white

$get-site-color-theme: var(--get-site-color-theme)

.ck-rounded-corners .ck.ck-balloon-panel, .ck.ck-balloon-panel.ck-rounded-corners
    z-index: 9999 !important

::-webkit-scrollbar
    width: 10px
::-webkit-scrollbar-track
    box-shadow: inset 0 0 5px grey
    border-radius: 100px
::-webkit-scrollbar-thumb
    background: $get-site-color-theme
    border: 1px solid grey
    border-radius: 10px
    &:hover
        background: grey
        box-shadow: inset 0 0 5px grey
