
.deal
    margin: 0 1rem
    padding-bottom: 1.5rem

.swiper-button-next:after
    font-size: 1.5rem !important
    color: lightblue

.swiper-button-prev:after
    font-size: 1.5rem !important
    color: lightblue
